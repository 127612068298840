var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has_breadcrumb"},[_c('GgPageHeader',{attrs:{"userInfo":_vm.responseData,"title":((_vm.responseData.firstName||'') + " " + (_vm.responseData.lastName||'')),"menuData":['Reset password'],"isUploadImg":false},on:{"menuCallback":_vm.openDialogPwd},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('div',{staticClass:"account-create",staticStyle:{"margin-bottom":"4px"}},[_vm._v(" "+_vm._s(_vm.responseData.email)+" ")]),_c('div',{staticClass:"account-info"},[_c('div',{staticClass:"account-create"},[_vm._v(" Created : "+_vm._s(_vm.dateFormat(_vm.responseData.createTime))+" ")]),_c('div',{staticClass:"account-sign"},[_vm._v(" Last sign in : "+_vm._s(_vm.timeFormat(_vm.responseData.lastLoginTime))+" ")])]),(_vm.responseData.status)?_c('div',{staticClass:"title-status"},[_vm._v(_vm._s(_vm.responseData.status[0].toUpperCase())+_vm._s(_vm.responseData.status.substring(1)))]):_vm._e(),(!_vm.responseData.status)?_c('div',{staticClass:"title-status"},[_vm._v("-")]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"content has_breadcrumb"},[_c('div',{staticClass:"connections-content"},[_c('edoovo-table',{ref:"connectionsTable",attrs:{"data":_vm.tableData,"default-value":"-","rowKey":"id","row-class-name":_vm.tableRowClassName}},[_c('edoovo-table-column',{attrs:{"size":"L","label":"Children","prop":"nickname","tooltip":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.photo)?_c('head-img',{staticClass:"table-cell-img",attrs:{"src":row.photo,"line-height":"48"},on:{"click":function () {}}}):_c('div',{staticClass:"table-cell-img"},[_c('span',{staticClass:"textPhoto"},[_vm._v(_vm._s(_vm.name(row.firstName,row.lastName)))])]),_c('label',{staticClass:"table-cell-name"},[_vm._v(_vm._s(row.firstName.charAt(0).toUpperCase() + row.firstName.slice(1))+" "+_vm._s(row.lastName.charAt(0).toUpperCase() + row.lastName.slice(1)))])],1)]}}])}),_c('edoovo-table-column',{attrs:{"size":"XL","label":"Schools","prop":"schoolImage","tooltip":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('img',{staticClass:"table-cell-img school-img",attrs:{"src":row.schoolImage}}),_c('label',{staticClass:"table-cell-name",attrs:{"title":((row.companyName) + " @ " + (row.schoolName))}},[_vm._v(_vm._s(((row.companyName) + " @ " + (row.schoolName))))])])]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Status","prop":"parentStatus","tooltip":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.status=='Withdrawn'?'Disconnected':'Connected')+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Date","prop":"modifyTime","tooltip":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('label',{staticClass:"table-cell-name"},[_vm._v(_vm._s(_vm.dateFormat(row.modifyTime)))])]}}])})],1)],1)]),_c('gg-Dialog',{ref:"resetPassword",attrs:{"visible":_vm.formDialogPwd.visible,"before-close":_vm.cancelResetPwd,"width":"580px"},on:{"update:visible":function($event){return _vm.$set(_vm.formDialogPwd, "visible", $event)}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Reset password for "),_c('span',{staticClass:"title-bold"},[_vm._v(_vm._s(_vm.formDialogPwd.name))])]),_c('div',[_c('ResetPassword',{attrs:{"autoSend":_vm.formDialogPwd.autoSend},on:{"sendEmail":_vm.resetByEmail},model:{value:(_vm.formDialogPwd.data),callback:function ($$v) {_vm.$set(_vm.formDialogPwd, "data", $$v)},expression:"formDialogPwd.data"}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('gg-cancel-button',{on:{"click":_vm.cancelResetPwd}},[_vm._v("Cancel")]),_c('gg-submit-button',{attrs:{"disabled":_vm.formDialogPwd.isDisabled},on:{"click":_vm.handleResetPwd}},[_vm._v("Reset")])],1)]),_c('Snackbar',{attrs:{"visible":_vm.snackbarData.visible,"content":_vm.snackbarData.content},on:{"update:visible":function($event){return _vm.$set(_vm.snackbarData, "visible", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }