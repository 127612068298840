<!-- parents-info-page  -->
<template>
  <div class="content has_breadcrumb">
   
<GgPageHeader
        :userInfo="responseData"
        :title="`${responseData.firstName||''} ${responseData.lastName||''}`"
        :menuData="['Reset password']"
        @menuCallback="openDialogPwd"
		:isUploadImg='false'
      >
        
        <template v-slot:info>
          <!-- <div class="info">
            <div class="status">{{ responseData.status }}</div>
            <div class="time">
              Created <span>20/02/2020</span> Last sign in:<span
                >13/02/2020 13:20</span
              >
            </div>
          </div> -->
          <!-- 最后一次登录时间 -->
		 
		  <div class="account-create" style="margin-bottom: 4px;">
		    {{  responseData.email}}
		  </div>
          <div class="account-info">
                <div class="account-create">
                  Created : {{ dateFormat(responseData.createTime) }}
                </div>
                <div class="account-sign">
                  Last sign in : {{ timeFormat(responseData.lastLoginTime) }}
                </div>
          </div>
          <!-- 当前帐号状态及首字母大写处理 -->
          <div class="title-status" v-if="responseData.status">{{ responseData.status[0].toUpperCase() }}{{ responseData.status.substring(1)}}</div>
          <div class="title-status" v-if="!responseData.status">-</div>
        </template>
      </GgPageHeader>
<!--    <div class="wraper">
      <div class="avatar-con">
        <div class="borderless-left">Photo</div>
        <head-img
          class="user-box"
          :src="responseData.photo"
          line-height="88"
          @click="() => {}"
        ></head-img>
      </div>
     <border-less-table :data="borderLessData">

	  </border-less-table>
    </div> -->
	<div class="content has_breadcrumb">
	  <!-- 表格 -->
	  <div class="connections-content">
	    <edoovo-table
	      ref="connectionsTable"
	      :data="tableData"
	      default-value="-"
	      rowKey="id"
	      :row-class-name="tableRowClassName"
	    >
	      <edoovo-table-column
	        size="L"
	        label="Children"
	        prop="nickname"
	        :tooltip="false"
	        v-slot="{ row }"
	      >
	        <div>
	          <head-img
	            class="table-cell-img"
	            :src="row.photo"
	            line-height="48"
	            @click="() => {}"
				  v-if='row.photo'
	          ></head-img>
				<div class="table-cell-img" v-else>
					<span class="textPhoto" >{{ name(row.firstName,row.lastName) }}</span>
				</div>
	          <label class="table-cell-name">{{row.firstName.charAt(0).toUpperCase() + row.firstName.slice(1)}} {{row.lastName.charAt(0).toUpperCase() + row.lastName.slice(1)}}</label>
	        </div>
	      </edoovo-table-column>
	      <edoovo-table-column
	        size="XL"
	        label="Schools"
	        prop="schoolImage"
	        :tooltip="false"
	        v-slot="{ row }"
	      >
	        <div>
	          <img :src="row.schoolImage" class="table-cell-img school-img" />
	          <label class="table-cell-name" :title=" `${row.companyName} @ ${row.schoolName}`">{{
	            `${row.companyName} @ ${row.schoolName}`
	          }}</label>
	        </div>
	      </edoovo-table-column>
	      <edoovo-table-column
	        size="M"
	        label="Status"
	        prop="parentStatus"
	        :tooltip="false"
			    v-slot="{ row }"
	      >
			{{row.status=='Withdrawn'?'Disconnected':'Connected'}}
			</edoovo-table-column>
	      <edoovo-table-column
	        size="M"
	        label="Date"
	        prop="modifyTime"
	        :tooltip="false"
			     v-slot="{ row }"
	      >
			 <label class="table-cell-name">{{ dateFormat(row.modifyTime)  }}</label>
			</edoovo-table-column>
	    </edoovo-table>
	  </div>
	</div>
	
	
    <!-- resetpassword -->
    <gg-Dialog
      :visible.sync="formDialogPwd.visible"
      :before-close="cancelResetPwd"
      width="580px"
      ref="resetPassword"
    >
      <div slot="title">
        Reset password for
        <span class="title-bold">{{ formDialogPwd.name }}</span>
      </div>
      <div>
        <ResetPassword
          @sendEmail="resetByEmail"
          :autoSend="formDialogPwd.autoSend"
          v-model="formDialogPwd.data"
        ></ResetPassword>
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="cancelResetPwd">Cancel</gg-cancel-button>
        <gg-submit-button
          :disabled="formDialogPwd.isDisabled"
          @click="handleResetPwd"
          >Reset</gg-submit-button
        >
      </span>
    </gg-Dialog>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { lightFormat } from "date-fns";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "parentsInfo",
  data() {
    return {
		 tableData: [],
      borderLessData: [
        { label: "Name", value: "", id: "name" },
        { label: "Role", value: "", id: "releationship" },
        { label: "Email", value: "", id: "email" },
        { label: "Phone", value: "", id: "phone" },
        { label: "Address", value: "", id: "homeAddress" },
        { label: "Address line 2", value: "", id: "homeAddressLine2" },
        { label: "Postal code", value: "", id: "postalCode" },
        { label: "Country", value: "", id: "country" },
      ],
      photo: "",
      formDialogPwd: {
        visible: false,
        data: "",
        autoSend: true,
        name: "",
        isDisabled: false,
      },
      parentUserId: sessionStorage.getItem("parentUserId"),
      responseData: {status:'unknown'},
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  watch: {
    "formDialogPwd.data": function (res) {
      this.formDialogPwd.isDisabled = res.length < 8;
    },
    // data4: {
    //   handler() {
    //     this.renderPass = this.slotData.every((item) => {
    //       return this.$refs[item].isError && !this.$refs[item].isError.show;
    //     });
    //     console.log("renderPass:", this.renderPass);
    //   },
    //   deep: true,
    // },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
	name() {
		return function(firstName,lastName){
			console.log(firstName)
			if (/[\u4e00-\u9fa5]/gi.test(firstName)) {
			  const allName = `${lastName}${firstName}`;
			  if (allName.length <= 2) {
			    return allName;
			  }
			  return (firstName || '').substr(0, 2);
			}
			return (
			  (firstName || '').substr(0, 1).toUpperCase() +
			  (lastName || '').substr(0, 1).toUpperCase()
			);
		}
		
	}
  },
  methods: {
	  tableRowClassName(cellData) {
	    if (cellData.status == "Withdrawn") {
	      return "opacity50";
	    }
	    return "";
	  },
    timeFormat(date) {
      if (!date) {
        return "-";
      }
      return moment.utc(date).local().format("DD/MM/YYYY hh:mm A");
    },
    dateFormat(date) {
      if (!date) {
        return "-";
      }
      return moment.utc(date).local().format("DD/MM/YYYY");
    },
    menuSelect(key, item) {
      console.log(key, item);
      this.activeIndex = key;
      this.$router.push(`/${key}`);
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    getData() {
		Ajax.get("/usermanage/student/selectByUserId", {
		  userId: sessionStorage.getItem("parentUserId"),
		}).then((res) => {
		  this.tableData = res.data || [];
		});
      // /parent/selectParentById
      Ajax.get("/usermanage/user/selectUserInfoById", {
        id: this.parentUserId,
      }).then((res) => {
		  
        this.responseData = res.data;
        this.borderLessData.forEach((item) => {
          item.value = res.data[item.id];
          if (item.id === "phone") {
            item.value = `${res.data["countryCode"]?res.data["countryCode"]:''} ${res.data["phoneNumber"]?res.data["phoneNumber"]:''}`;
          }
		  if (item.id === "name") {
		    item.value = `${res.data["firstName"]||'-'} ${res.data["lastName"]||''}`;
		  }
		  if(item.value == '' || item.value == null){
			  item.value = '-'
		  }
		  this.responseData.avatar=res.data["photo"];
          this.photo = res.data["photo"];
        });
      });
    },
    resetByEmail(val) {
      this.formDialogPwd.autoSend = val;
      if (val || this.formDialogPwd.data.length > 8) {
        this.formDialogPwd.isDisabled = false;
      } else {
        this.formDialogPwd.isDisabled = true;
      }
    },
    openDialogPwd() {
      this.formDialogPwd.name = `${this.responseData.firstName} ${this.responseData.lastName}`;
      this.formDialogPwd.autoSend = true;
      this.formDialogPwd.isDisabled = false;
      this.formDialogPwd.visible = true;
    },
    cancelResetPwd() {
      this.formDialogPwd.visible = false;
      this.formDialogPwd.data = "";
    },
    handleResetPwd() {
      this.formDialogPwd.visible = false;
      let isAuto = this.formDialogPwd.autoSend ? "1" : "0";
      let params = {
        userId: this.parentUserId,
        newPasswd: this.formDialogPwd.data,
        isAuto: isAuto,
      };
      Ajax.post("/usermanage/password/resetPasswd", params).then((res) => {
        if (res.code === "0000") {
          this.handleSnack("Password has been reset");
          this.formDialogPwd.data = "";
        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.avatar-con {
  display: flex;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  width: 492px;
  .borderless-left {
    margin-right: 20px;
    min-width: 140px;
    max-width: 220px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    color: #202124;
  }
}
.info {
  color: #b4b4b4;
  font-size: 14px;
  font-family: Roboto;
  .status {
    color: #0b8043;
  }
}
/deep/.iconBox{
	display: none!important;
}
.avatar-con {
  display: flex;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  width: 492px;
  .borderless-left {
    margin-right: 20px;
    min-width: 140px;
    max-width: 220px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    color: #202124;
  }
}
.info {
  color: #b4b4b4;
  font-size: 14px;
  font-family: Roboto;
  .status {
    color: #0b8043;
  }
}
.connections-content {
  border: 1px solid #e0e0e0;
  border-radius: 13px;
  overflow: hidden;

  /deep/ .edoovo-table-cell-inner {
    font-family: Roboto;
    color: #202124;
    font-size: 14px;
  }
  /deep/ .edoovo-thead .edoovo-table-cell-inner {
    font-family: OpenSans-Bold;
    color: #5f6368;
    font-size: 20px;
  }
  /deep/ .edoovo-thead .edoovo-table-cell {
    background: none;
  }
  /deep/ .edoovo-tbody-row {
    height: 88px;
  }
  /deep/.edoovo-tbody-row:last-child .edoovo-table-cell {
    border-bottom: 0;
  }

  .school-img {
    width: 80px;
    height: 40px;
    object-fit: cover;
  }
  /deep/.edoovo-tbody-row.opacity50 {
    opacity: 0.5;
    color: #b4b4b4;
  }
}
.textPhoto{
	line-height: 48px;
	    height: 48px;
	    width: 48px;
		display: block;
		    background: #ddd;
			border-radius: 50%;
			overflow: hidden;
			text-align: center;
			font-size: 16px;
			    font-family: Poppins;
			    font-weight: 500;
			    color: #ffffff;
}
/deep/.el-image img{
		height: 100%;
		object-fit: cover!important;
	}
	/deep/.ggPage-main-left{
		display: none!important;
	}
	/deep/.account-create{
		font-family: Roboto;
	}
</style>